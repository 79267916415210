import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Spinner,
  Stack,
  Text,
  createIcon,
  useToast,
} from "@chakra-ui/react";
import { BiCart } from "react-icons/bi";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Variants, useAnimation, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import SocialMedia from "./SocialMedia";
import { useDispatch, useSelector } from "react-redux";
import { getAllActiveLanding } from "../Redux/action/landing.action";
import ErrorCard from "./Common/ErrorCard";
import { Loading } from "./Common/Components";

export const GradientBackgrounds = ({
  image,
  type,
}: {
  type?: string;
  image: string;
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <Box position="absolute" top={0} left={0} width="100%" height="100%">
      <Box
        display={"flex"}
        justifyContent={"center"}
        position="relative"
        // width="100%"
        height="100%"
      >
        {loading && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Spinner size="xl" color="white" />
          </Box>
        )}
        <Image
          src={image}
          alt="Background"
          objectFit="cover"
          width="unset !important"
          height="100%"
          // position="absolute"
          // top={0}
          // left={0}
          onLoad={() => setLoading(false)}
          display={loading ? "none" : "block"}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          background={
            type === "PicturePage"
              ? ""
              : "linear-gradient(360deg, rgb(0 0 0 / 67%), rgb(0 0 0 / 27%))"
          }
        />
      </Box>
    </Box>
  );
};

export const Arrow = createIcon({
  displayName: "Arrow",
  viewBox: "0 0 72 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
      fill="currentColor"
    />
  ),
});

export interface HeadingVariants extends Variants {
  hidden: {
    opacity: number;
    y: number;
  };
  visible: {
    opacity: number;
    y: number;
  };
}

const MainCarousel = () => {
  const controls = useAnimation();

  // Use the useEffect hook to animate the text elements on mount
  useEffect(() => {
    controls.start("visible");
  }, [controls]);

  // Define animation properties for the text elements
  const textAnimation: HeadingVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const dispatch = useDispatch();
  const toast = useToast();

  const _getAllActiveLanding = useSelector(
    (state: { getAllActiveLanding: any }) => state.getAllActiveLanding
  );
  const { loading: landingLoading, landings, error } = _getAllActiveLanding;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getAllActiveLanding(toastPositions, toast));
  }, [dispatch, toast]);

  return (
    <Box
      overflow="hidden"
      // borderRadius={{ base: "30px", sm: "15px", md: "30px" }}
      position="relative"
      height="100%"
    >
      {!landingLoading && !error ? (
        <Carousel
          stopOnHover={true}
          emulateTouch={true}
          autoPlay={true}
          infiniteLoop
          autoFocus
          interval={4000}
          showArrows={true}
          showStatus={false}
          showIndicators={true}
          thumbWidth={3}
          dynamicHeight={false}
          showThumbs={false}
        >
          {landings?.map((landing: any, index: number) => (
            <Box
              key={index}
              position="relative"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              overflow="hidden"
              // borderRadius={{ base: "30px", sm: "15px", md: "30px" }}
            >
              <GradientBackgrounds image={landing?.media} />

              <Stack
                position={"relative"}
                as={Box}
                textAlign={"start"}
                spacing={{ base: 4, md: 7 }}
                width={{ base: "97%", sm: "100%", md: "60%" }}
                alignItems={"start"}
                padding={{
                  base: "120px 20px 20px 43px",
                  sm: "53px 20px 20px 43px",
                  md: "120px 20px 20px 43px",
                }}
              >
                <Box>
                  <motion.div variants={textAnimation}>
                    <Text color={"white"} fontSize="lg">
                      {landing?.subTitle}
                    </Text>
                  </motion.div>
                  <motion.div variants={textAnimation}>
                    <Heading
                      fontWeight={600}
                      lineHeight={"110%"}
                      mb={2}
                      fontSize={{ base: "4xl", sm: "4xl", md: "6xl" }}
                      as={"span"}
                      color={"white"}
                    >
                      {landing?.title}
                    </Heading>
                  </motion.div>
                </Box>
                <motion.div variants={textAnimation}>
                  <Text color={"white"}>{landing?.details}</Text>
                </motion.div>
                <Stack position={"relative"}>
                  <Button
                    as={Link}
                    to={landing?.link}
                    target="_blank"
                    fontSize={"18px"}
                    colorScheme={"whiteAlpha"}
                    variant={"outline"}
                    borderColor={"white"}
                    borderWidth={"1.5px"}
                    color={"white"}
                    background={"whiteAlpha.300"}
                    rounded={"8px"}
                    px={6}
                    display={"flex"}
                    gap={"10px"}
                  >
                    Order Now
                    <BiCart fontSize={"22px"} width={"40px"} height={"40px"} />
                  </Button>
                  <motion.div variants={textAnimation}>
                    <Box>
                      <Icon
                        as={Arrow}
                        color={"gray.100"}
                        w={71}
                        position={"absolute"}
                        right={-71}
                        top={"10px"}
                      />
                      <Text
                        color={"gray.100"}
                        fontSize={"lg"}
                        fontFamily={"Caveat"}
                        position={"absolute"}
                        right={"-125px"}
                        top={"-15px"}
                        transform={"rotate(10deg)"}
                        textTransform={"capitalize"}
                      >
                        order custom gift
                      </Text>
                    </Box>
                  </motion.div>
                </Stack>
              </Stack>

              {/* social m */}
              <SocialMedia
                position="absolute"
                width={"100%"}
                justifyContent={"center"}
                bottom="40px"
                left="0"
                color={"white"}
              />
            </Box>
          ))}
        </Carousel>
      ) : error ? (
        <Flex
          height="50vh"
          width="full"
          alignItems="center"
          justifyContent="center"
        >
          <Box width="200px">
            <ErrorCard message={error} />
          </Box>
        </Flex>
      ) : (
        <Flex
          height="100vh"
          width="full"
          alignItems="center"
          justifyContent="center"
        >
          <Loading />
        </Flex>
      )}
    </Box>
  );
};
export default MainCarousel;
